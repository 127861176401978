var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accessMake"},[(!_vm.isBusinessId)?_c('backtitle'):_vm._e(),_c('div',{staticClass:"mainContent",attrs:{"id":"mainContent"}},[_c('div',{staticClass:"modelBox",attrs:{"data-title":"融资申请信息"}},[_c('text-title',{attrs:{"index":"01","label":"融资申请信息"}}),_c('form-info',{attrs:{"componentList":_vm.financingApplicationFrom,"model":_vm.financingApplicationData,"showbor":true,"count":_vm.financCount}})],1),_c('div',{staticClass:"modelBox",attrs:{"data-title":"标的资产"}},[_c('text-title',{attrs:{"index":"02","label":"标的资产"}}),_c('div',{staticClass:"head"},[_c('title-center',{attrs:{"rules":false,"name":"资产信息"}})],1),_c('base-table',{attrs:{"columns":_vm.assetInfoColumn,"tableAttrs":{
          data: _vm.assetInfoData,
          stripe: true,
        },"isCaculateHeight":false,"showPage":false},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}},{key:"easNo",fn:function(scope){return [_c('div',{staticClass:"lookPaymentOrder",on:{"click":function($event){return _vm.lookPaymentOrder(scope.row)}}},[_vm._v(_vm._s(scope.row.easNo))])]}}])}),_c('div',{staticClass:"head"},[_c('title-center',{attrs:{"rules":false,"name":"项目信息"}})],1),_c('base-table',{attrs:{"columns":_vm.projectInfoColumn,"tableAttrs":{
          data: _vm.projectInfoData,
          stripe: true,
        },"isCaculateHeight":false,"showPage":false},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}])})],1),_c('div',{staticClass:"modelBox",attrs:{"data-title":"担保信息"}},[_c('text-title',{attrs:{"index":"03","label":"担保信息"}}),_c('div',{staticClass:"head"},[_c('title-center',{attrs:{"rules":false,"name":"个人担保"}})],1),_c('base-table',{attrs:{"columns":_vm.personalGuarantee,"tableAttrs":{
          data: _vm.personalData,
          stripe: true,
        },"isCaculateHeight":false,"showPage":false},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_c('base-table',{attrs:{"columns":_vm.personalFormChildren,"tableAttrs":{
              data: scope.row.personContactInfos,
              stripe: true,
            },"isCaculateHeight":false,"showPage":false}})]}},{key:"index",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}])}),_c('div',{staticClass:"head"},[_c('title-center',{attrs:{"rules":false,"name":"企业担保"}})],1),_c('base-table',{attrs:{"columns":_vm.enterpriseGuarantee,"tableAttrs":{
          data: _vm.enterpriseData,
          stripe: true,
        },"isCaculateHeight":false,"showPage":false},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(_vm._s(scope.$index + 1))]}}])})],1),_c('div',{staticClass:"modelBox",attrs:{"data-title":"备案条款"}},[_c('text-title',{attrs:{"index":"04","label":"备案条款"}}),_c('base-table',{attrs:{"columns":_vm.filingTermsColumn,"tableAttrs":{
          data: _vm.filingTermsData,
          stripe: true,
        },"isCaculateHeight":false,"showPage":false},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}])})],1),_c('div',{staticClass:"modelBox",attrs:{"data-title":"问题汇总"}},[_c('text-title',{attrs:{"index":"05","label":"问题汇总"}}),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.problemSummary))])],1),_c('div',{staticClass:"modelBox",attrs:{"data-title":"提示"}},[_c('text-title',{attrs:{"index":"06","label":"提示"}}),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.tipsDescription))])],1),_c('div',{staticClass:"modelBox",attrs:{"data-title":"附件"}},[_c('text-title',{attrs:{"index":"07","label":"附件"}}),_c('base-table',{attrs:{"columns":_vm.accessoriesColumn,"tableAttrs":{
          data: _vm.accessoriesData,
          stripe: true,
        },"isCaculateHeight":false,"showPage":false},scopedSlots:_vm._u([{key:"fileListDetailVOS",fn:function(scope){return _vm._l((scope.row.fileListDetailVOS),function(item){return _c('div',{key:item.fileId},[_c('div',{staticClass:"filelist"},[_c('span',[_vm._v(_vm._s(item.fileName))]),_c('icon-button',{attrs:{"content":"预览","icon":"iconfont iconyulan"},on:{"click":function($event){return _vm.previewShow(item)}}})],1)])})}},{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}])})],1),_c('div',{staticClass:"modelBox",attrs:{"data-title":"文件生成与下载"}},[_c('text-title',{attrs:{"index":"08","label":"文件生成与下载"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"left"},[_c('el-input',{attrs:{"disabled":true},model:{value:(_vm.fileMakeData.fileName),callback:function ($$v) {_vm.$set(_vm.fileMakeData, "fileName", $$v)},expression:"fileMakeData.fileName"}})],1),(_vm.fileMakeData.fileId && _vm.fileMakeData.fileId !== '0')?_c('icon-button',{attrs:{"content":"预览","icon":"iconfont iconyulan"},on:{"click":function($event){return _vm.previewShow(_vm.fileMakeData)}}}):_vm._e()],1)],1)]),(!_vm.isBusinessId)?_c('div',{staticClass:"footer"},[_c('base-button',{attrs:{"label":"关 闭","type":"default"},on:{"click":_vm.close}})],1):_vm._e(),_c('pre-view',{attrs:{"fileId":_vm.fileData.fileId,"isOpen":true,"fileType":_vm.fileData.fileFileSuffix,"count":_vm.fileCount}}),(_vm.visibleOrderDetails)?_c('payment-order-details-dialog',{attrs:{"visible":_vm.visibleOrderDetails,"keyId":_vm.keyId},on:{"update:visible":function($event){_vm.visibleOrderDetails=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }