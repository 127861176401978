import cooperationInfo from '../components/cooperation-info.vue'
import { getDictLists, filterMultiple } from '@/filters/fromDict'
import Storage from '@/utils/storage'

// 融资申请信息
export const financingApplicationConfig = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      cols
    },
    {
      label: '融资产品',
      prop: 'finaaceProductName',
      cols
    },
    {
      label: '供应商名称',
      prop: 'gysCompanyName',
      cols: { lg: 10 }
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode',
      cols
    },
    {
      label: '融资金额(元)',
      prop: 'applyAmount',
      type: 'Amount',
      cols
    },
    {
      label: '融资期限',
      prop: 'financeTerm',
      type: 'termOfValidity',
      validTimeUnit: content.financingApplicationData.financeTermUnit,
      option: getDictLists('DATE_UNIT'),
      selectedField: ['dictId', 'dictName'],
      cols

    },
    {
      label: '综合融资利率(%)',
      prop: 'financeRate',
      cols

    },
    {
      label: '业务所属区域',
      prop: 'area',
      cols
    },
    {
      label: '供应商类型',
      prop: 'supplierTypeName',
      cols
    },
    {
      // 我司合作情况
      prop: 'cooperationType',
      cols,
      attrs: {
        placeholder: '请输入',
        options: content.cooperationType,
        gysId: content.gysId,
        disabled: true
      },
      tag: cooperationInfo
    },
    {
      label: '资金方',
      prop: 'capitalAndProductName',
      cols
    },
    {
      label: '成本价格（%）',
      prop: 'costPrice',
      cols

    },
    {
      label: Storage.getLocal('admittanceInfo').finaaceProductName === '稳享付' ? '综合质押率(%)' : '折价率(%)',
      prop: 'totalPledgeRate',
      cols

    },
    // {
    //   label: '服务费率(%)',
    //   prop: 'serviceRate',
    //   cols: { lg: 5 }
    // },
    {
      label: '是否同意账户变更',
      prop: 'changeOrNot',
      cols: { lg: 5 }
    }

  ]
}
// 标的资产--资产信息
export const assetInfoTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60px'
    },
    // {
    //   label: '付款单',
    //   prop: 'easNo',
    //   minWidth: '150px',
    //   align: 'left',
    //   isHidden: (Storage.getLocal('admittanceInfo').finaaceProductName === '稳享付')
    // },
    {
      label: '合同名称',
      prop: 'finContractName',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '合同编号',
      prop: 'finContractCode',
      'show-overflow-tooltip': true
    },
    {
      label: '实际合同名称',
      prop: 'finContractName',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '项目公司名称',
      prop: 'xmgsName',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '合同对价(元)',
      prop: 'finContractAmount',
      align: 'right',
      formatter: row => {
        return (row.finContractAmount ? row.finContractAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '转让价值(元)',
      prop: 'pledgeValue',
      align: 'right',

      formatter: row => {
        return (row.pledgeValue ? row.pledgeValue.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '对应融资额(元)',
      prop: 'relLoanAmount',
      align: 'right',

      formatter: row => {
        return (row.relLoanAmount ? row.relLoanAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '质押率(%)',
      prop: 'pledgeRate'
    }

  ]
}
// 标的资产--项目信息
export const projectInfoTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60px'
    },
    {
      label: '项目公司名称',
      prop: 'projectName',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '项目城市',
      prop: 'city',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '金地控股比例（%）',
      prop: 'shareholdingRatio',
      minWidth: '90px'
    },
    {
      label: '金地控股主体',
      prop: 'controllingEntity',
      minWidth: '150px'
    }
  ]
}
// 备案条款数据
export const filingTermsTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60px'
    },
    {
      label: '备案条款',
      prop: 'filingTerms',
      minWidth: '200px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '审核结果',
      prop: 'approveResultName',
      minWidth: '150px'
    }

  ]
}
// 附件
export const accessoriesTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60px'
    },
    {
      label: '文件类型',
      prop: 'documentName',
      minWidth: '200px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '是否已提供',
      // 'render-header': renderHeader,
      prop: 'isProvideName',
      with: '90px'
    },
    {
      label: '文件个数',
      prop: 'fileNum',
      with: '90px'
    },
    {
      label: '文件名称',
      prop: 'fileListDetailVOS',
      minWidth: '200px',
      HiddenOverflow: true,
      showOverflowTooltip: false
    }
  ]
}
// 尽调安排
export const arrangementFormData = (content) => {
  return [
    {
      label: '预计尽调时间',
      prop: 'expectDueDiligenceDateName'
    },
    {
      label: '预计尽调地点',
      prop: 'expectDueDiligenceAddress'
    },
    {
      label: '预计上会时间',
      prop: 'expectMeetingTimeName'
    }
  ]
}
// 企业担保
export const enterpriseTable = () => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '企业名称',
      prop: 'companyName',
      align: 'left'
    },
    {
      label: '统一社会信用代码',
      prop: 'nsrsbh'
    }

  ]
}

// 个人担保
export const personalTabel = (content) => {
  return [
    {
      prop: 'code',
      type: 'expand'
    },
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personType',
      formatter: row => {
        return filterMultiple('PERSONNEL_TYPE', row.personType, 'String')
      }
    }
  ]
}
// 个人担保子表格
export const personalFormChildren = (content) => {
  return [

    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '关系',
      prop: 'contactType',
      formatter: row => {
        const [obj] = getDictLists('PERSON_CONTACT_TYPE').filter(item => item.dictId === row.contactType.toString())
        return obj ? obj.dictName : '-'
      }
    },
    {
      label: '家庭地址',
      prop: 'address',
      formatter: row => {
        return row.address ? row.address : '--'
      }
    }

  ]
}
