// 稳享付----准入文件制作
<template>
  <div class="accessMake">
    <backtitle v-if="!isBusinessId"></backtitle>
    <div class="mainContent" id="mainContent">
      <div data-title="融资申请信息" class="modelBox">
        <text-title index="01" label="融资申请信息"></text-title>
        <form-info
          :componentList="financingApplicationFrom"
          :model="financingApplicationData"
          :showbor="true"
          :count="financCount"
        />
      </div>
      <div data-title="标的资产" class="modelBox">
        <text-title index="02" label="标的资产"></text-title>
        <div class="head">
         <title-center :rules="false" name="资产信息"></title-center>
        </div>
        <base-table
          :columns="assetInfoColumn"
          :tableAttrs="{
            data: assetInfoData,
            stripe: true,
          }"
          :isCaculateHeight="false"
          :showPage="false"
        >
          <template slot="code" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
          <template slot="easNo" slot-scope="scope">
             <!-- <div class="lookPaymentOrder" @click="lookPaymentOrder(scope.row)" v-for="(item,index) of scope.row.easNo" :key="index">{{item}}</div> -->
             <div class="lookPaymentOrder" @click="lookPaymentOrder(scope.row)" >{{scope.row.easNo}}</div>
          </template>
        </base-table>
        <div class="head">
         <title-center :rules="false" name="项目信息"></title-center>
        </div>
        <base-table
          :columns="projectInfoColumn"
          :tableAttrs="{
            data: projectInfoData,
            stripe: true,
          }"
          :isCaculateHeight="false"
          :showPage="false"
        >
          <template slot="code" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </base-table>
      </div>
      <div data-title="担保信息" class="modelBox">
        <text-title index="03" label="担保信息"></text-title>
        <!-- 个人担保 -->
        <div class="head">
         <title-center :rules="false" name="个人担保"></title-center>
         </div>
        <base-table
          :columns="personalGuarantee"
          :tableAttrs="{
            data: personalData,
            stripe: true,
          }"
          :isCaculateHeight="false"
          :showPage="false"
        >
          <!-- 插入子表格 -->
          <template slot="code" slot-scope="scope">
            <base-table
              :columns="personalFormChildren"
              :tableAttrs="{
                data: scope.row.personContactInfos,
                stripe: true,
              }"
              :isCaculateHeight="false"
              :showPage="false"
            >
            </base-table>
          </template>
          <template slot="index" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </base-table>
        <!-- 企业担保 -->
         <div class="head">
         <title-center :rules="false" name="企业担保"></title-center>
         </div>
        <base-table
          :columns="enterpriseGuarantee"
          :tableAttrs="{
            data: enterpriseData,
            stripe: true,
          }"
          :isCaculateHeight="false"
          :showPage="false"
        >
          <template slot="code" slot-scope="scope">{{
            scope.$index + 1
          }}</template>
        </base-table>
      </div>
      <div data-title="备案条款" class="modelBox">
        <text-title index="04" label="备案条款"></text-title>
        <base-table
          :columns="filingTermsColumn"
          :tableAttrs="{
            data: filingTermsData,
            stripe: true,
          }"
          :isCaculateHeight="false"
          :showPage="false"
        >
          <template slot="code" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </base-table>
      </div>
      <div data-title="问题汇总" class="modelBox">
        <text-title index="05" label="问题汇总"></text-title>
        <div class="tips">{{ problemSummary }}</div>
      </div>
      <div data-title="提示" class="modelBox">
        <text-title index="06" label="提示"></text-title>
        <div class="tips">{{ tipsDescription }}</div>
      </div>
      <div data-title="附件" class="modelBox">
        <text-title index="07" label="附件"></text-title>
        <base-table
          :columns="accessoriesColumn"
          :tableAttrs="{
            data: accessoriesData,
            stripe: true,
          }"
          :isCaculateHeight="false"
          :showPage="false"
        >
          <template slot="fileListDetailVOS" slot-scope="scope">
            <div
              v-for="item in scope.row.fileListDetailVOS"
              :key="item.fileId"
            >
              <div class="filelist">
                <span>{{ item.fileName }}</span>
                <icon-button
                  content="预览"
                  icon="iconfont iconyulan"
                  @click="previewShow(item)"
                />
              </div>
            </div>
          </template>
          <template slot="code" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
          <!-- 文件名称 -->
        </base-table>
      </div>
      <div data-title="文件生成与下载" class="modelBox">
        <text-title index="08" label="文件生成与下载"></text-title>
        <div class="row">
          <div class="left">
            <el-input
              :disabled="true"
              v-model="fileMakeData.fileName"
            ></el-input>
          </div>
          <icon-button
            content="预览"
            icon="iconfont iconyulan"
            @click="previewShow(fileMakeData)"
            v-if="fileMakeData.fileId && fileMakeData.fileId !== '0'"
          />
        </div>
      </div>
    </div>
    <div class="footer" v-if="!isBusinessId">
      <base-button label="关 闭" type="default" @click="close"></base-button>
    </div>
    <!-- 文件预览 -->
    <pre-view
      :fileId="fileData.fileId"
      :isOpen="true"
      :fileType="fileData.fileFileSuffix"
      :count="fileCount"
    />
    <!-- 付款单详情信息 -->
    <payment-order-details-dialog :visible.sync="visibleOrderDetails" v-if="visibleOrderDetails" :keyId="keyId"></payment-order-details-dialog>
  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import TextTitle from '@/components/packages/text-title/text-title.vue'
import Backtitle from '../../components/backtitle.vue'
import baseButton from '@/components/common/button/base-button/base-button.vue'
import PaymentOrderDetailsDialog from '@/pages/assets-manage/payment-order-details/components/payment-order-details-dialog.vue'
// import BaseForm from '@/components/common/base-form/base-form'
// import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import FormInfo from '@/components/packages/form-info/form-info-new.vue'
import {
  financingApplicationConfig,
  assetInfoTable,
  projectInfoTable,
  filingTermsTable,
  accessoriesTable,
  arrangementFormData,
  enterpriseTable,
  personalTabel,
  personalFormChildren
} from '../utils/detial-config'
import baseTable from '@/components/common/table/base-table/base-table.vue'
import Storage from '@/utils/storage'
import { getDictLists, getDict } from '@/filters/fromDict'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { formatDate } from '@/utils/auth/common'
import TitleCenter from '@/pages/business/components/title-center.vue'

export default {
  components: {
    TextTitle,
    Backtitle,
    baseButton,
    FormInfo,
    baseTable,
    IconButton,
    PreView,
    TitleCenter,
    PaymentOrderDetailsDialog
  },
  props: {
    baseData: Object,
    isBusinessId: String
  },
  data () {
    return {
      fileData: {},
      financingApplicationData: {}, // 融资申请信息
      assetInfoData: [], // 标的资产信息
      projectInfoData: [],
      filingTermsData: [], // 备案条款
      accessoriesData: [], // 附件
      arrangementData: {}, // 尽调安排
      count: 0,
      tipsDescription: '', // 提示
      expectDueDiligence: {}, // 尽调数据
      fileMakeData: {}, // 文件制作名称
      passArr: [],
      gysId: Storage.getLocal('admittanceInfo').gysId,
      enterpriseData: [], // 企业担保数据
      personalData: [], // 个人担保数据
      problemSummary: '',
      fileCount: 0,
      financCount: 0,
      visibleOrderDetails: false,
      keyId: ''
    }
  },
  created () {
    this.businessId = this.$route.query.businessId || this.isBusinessId
    this.getAssetsInfo()
    this.getRecordTableData()
    this.getEnclosureTableData()
    this.getPersonalData()
    this.getEnterpriseData()
  },
  watch: {
    baseData: {
      handler: function (val) {
        if (val) {
          // 申请信息
          this.financingApplicationData = val
          this.financingApplicationData.supplierTypeName = val.supplierType ? getDict('SUPPLIER_TYPE', val.supplierType) : '--'
          this.financingApplicationData.areaName = this.financingApplicationData.serviceRate.areaId ? getDict('BUSINESS_AREA', val.areaId) : '--'
          this.financingApplicationData.capitalAndProductName = val.capitalSideName ? val.capitalSideName + '-' + val.capitalSideProductName : '--'
          this.financingApplicationData.serviceRate = val.serviceRate.toString()
          this.financCount++
          // 提示
          this.tipsDescription = val?.tips
          // 问题汇总
          this.problemSummary = val?.problemSummary
          const {
            expectDueDiligenceAddress,
            expectDueDiligenceDate,
            expectMeetingTime
          } = { ...val }
          // 尽调安排
          this.expectDueDiligence = {
            expectDueDiligenceAddress,
            expectDueDiligenceDateName: expectDueDiligenceDate
              ? formatDate(expectDueDiligenceDate, 'YY-MM-DD')
              : '',
            expectMeetingTimeName: expectMeetingTime
              ? formatDate(expectMeetingTime, 'YY-MM-DD')
              : ''
          }
          // 生成文件
          this.fileMakeData = val.fileListInfo ? val.fileListInfo : {}
        }
        this.count++
      },
      deep: true
    }
  },
  computed: {
    // 合作类型字典
    cooperationType () {
      return getDictLists('COMPANY_COOPERATION_TYPE').map((item) => {
        return { value: item.dictId, label: item.dictName }
      })
    },
    financingApplicationFrom () {
      return financingApplicationConfig(this)
    },
    assetInfoColumn () {
      return assetInfoTable(this)
    },
    projectInfoColumn () {
      return projectInfoTable(this)
    },
    filingTermsColumn () {
      return filingTermsTable(this)
    },
    accessoriesColumn () {
      return accessoriesTable(this)
    },
    arrangementForm () {
      return arrangementFormData(this)
    },
    enterpriseGuarantee () {
      return enterpriseTable(this)
    },
    personalGuarantee () {
      return personalTabel(this)
    },
    personalFormChildren () {
      return personalFormChildren(this)
    }
  },
  methods: {
  // 获取标的资产
    getAssetsInfo () {
      financeAdmittanceApi
        .assetsInfo({ businessId: this.businessId })
        .then((res) => {
          this.assetInfoData = res.data.finContractInfoList
            ? res.data.finContractInfoList
            : []

          // if (this.assetInfoData.length > 0) {
          //   this.assetInfoData.forEach((item) => {
          //     if (item.easNo) {
          //       item.paymentOrder = item.easNo.split(',')
          //     }
          //   })
          // }
          this.projectInfoData = res.data.finProjectInfoList
            ? res.data.finProjectInfoList
            : []
          this.projectInfoData = this.projectInfoData?.map((item) => {
            return { projectAddress: item.province + item.city, ...item }
          })
        })
    },
    // 获取个人担保基础数据
    getPersonalData () {
      financeAdmittanceApi.getPersonnalList({ businessId: this.businessId }).then(res => {
        console.log(res.data, '个人数据')
        this.personalData = res.data
      })
    },
    // 获取企业担保基础数据
    getEnterpriseData () {
      financeAdmittanceApi.getEnterpriseList({ businessId: this.businessId }).then(res => {
        console.log(res.data, '企业数据')
        this.enterpriseData = res.data
      })
    },
    // 获取备案条款
    getRecordTableData () {
      financeAdmittanceApi
        .getAdmittanceFinling({ businessId: this.businessId })
        .then((res) => {
          if (res.data) {
            this.filingTermsData = res.data.map((item) => {
              return {
                ...item,
                approveResultName: item.approveResult === '0' ? '是' : '否'
              }
            })
          }
        })
    },
    // 初始化数据
    getEnclosureTableData () {
      financeAdmittanceApi
        .getAdmittanceFile({ businessId: this.businessId })
        .then((res) => {
        // this.accessoriesData = res.data
          this.accessoriesData = res.data.map((item) => {
            return {
              ...item,
              isProvideName: item.isProvide === '0' ? '否' : '是'
            }
          })
        })
    },
    // 预览
    previewShow (data) {
      if (data && data.fileId) {
        this.$set(this.fileData, 'fileId', data.fileId)
        this.$set(this.fileData, 'fileFileSuffix', data.fileName.substr(data.fileName.lastIndexOf('.') + 1, 4))
        this.fileCount++
      }
    },
    close () {
      this.$router.back()
    },
    // 查看付款单
    lookPaymentOrder (row) {
      this.keyId = row.payApplyKeyId
      this.visibleOrderDetails = true
    }
  }
}
</script>
<style lang="scss" scoped>
.accessMake {
  padding: 0px 10px;
  height: 100%;
  background: #f9f9f6;
  .mainContent {
    background: #fff;
    height: calc(100% - 105px);
    overflow-y: scroll;
    .lookPaymentOrder {
      color: #0d96f1;
      cursor: pointer;
      min-width: 150px;
       display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .footer {
    text-align: center;
    margin: 0px 0 10px;
    padding: 14px 0;
    box-shadow: 0px 0px 13px 2px rgba(#4A7CF0, 0.2);
    background: #ffffff;
  }
}
.head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  height: 30px;
}
.tips {
  font-size: 16px;
  margin: 10px;
  width: 100%;
  height: 100px;
}
.filelist {
  display: flex;
  justify-content: center;
}
.row {
  display: flex;
  padding: 20px;
  .left {
    width: 200px;
  }
}
/deep/ .el-radio-group{
  margin-top: 10px;
}
</style>
